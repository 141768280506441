// API FORMAT
// {
//     src: "/images/image2_11800x900.jpg",
//     width: 11800,
//     height: 900,
//      tags: [],
//     srcSet: [
//         { src: "/images/image2_800x450.jpg", width: 800, height: 450 },
//         { src: "/images/image2_400x225.jpg", width: 400, height: 225 },
//     ],
// },

/**
 * Images Source Folder
 * D:\Github\004_react-hivac-web-application\src\contents\images
 */

const images = [
	{
		src: "/assets/gallery/image-1.jpg",
		alt: "IMAGE 1",
		width: 11800,
		height: 900,
		tags: ["worker"],
		srcSet: [],
	},
	{
		src: "/assets/gallery/image-2.jpg",
		alt: "IMAGE 2",
		width: 11800,
		height: 900,
		tags: ["worker"],
		srcSet: [],
	},
	{
		src: "/assets/gallery/image-3.jpg",
		alt: "IMAGE 3",
		width: 11800,
		height: 900,
		tags: ["worker"],
		srcSet: [],
	},
];

// const images = [
// 	{
// 		url: "https://picsum.photos/id/1/1800/1800",
// 		alt: "Responsive image 1",
// 	},
// 	{
// 		url: "https://picsum.photos/id/2/1800/1800",
// 		alt: "Responsive image 2",
// 	},
// 	{
// 		url: "https://picsum.photos/id/3/1800/1800",
// 		alt: "Responsive image 3",
// 	},
// 	{
// 		url: "https://picsum.photos/id/4/1800/1800",
// 		alt: "Responsive image 4",
// 	},
// 	{
// 		url: "https://picsum.photos/id/5/1800/1800",
// 		alt: "Responsive image 5",
// 	},
// 	{
// 		url: "https://picsum.photos/id/6/1800/1800",
// 		alt: "Responsive image 6",
// 	},
// 	{
// 		url: "https://picsum.photos/id/7/1800/1800",
// 		alt: "Responsive image 7",
// 	},
// 	{
// 		url: "https://picsum.photos/id/8/1800/1800",
// 		alt: "Responsive image 8",
// 	},
// 	{
// 		url: "https://picsum.photos/id/9/1800/1800",
// 		alt: "Responsive image 9",
// 	},
// 	{
// 		url: "https://picsum.photos/id/10/1800/1800",
// 		alt: "Responsive image 10",
// 	},
// 	{
// 		url: "https://picsum.photos/id/11/1800/1800",
// 		alt: "Responsive image 11",
// 	},
// 	{
// 		url: "https://picsum.photos/id/1/1800/1800",
// 		alt: "Responsive image 1",
// 	},
// 	{
// 		url: "https://picsum.photos/id/2/1800/1800",
// 		alt: "Responsive image 2",
// 	},
// 	{
// 		url: "https://picsum.photos/id/3/1800/1800",
// 		alt: "Responsive image 3",
// 	},
// 	{
// 		url: "https://picsum.photos/id/4/1800/1800",
// 		alt: "Responsive image 4",
// 	},
// 	{
// 		url: "https://picsum.photos/id/5/1800/1800",
// 		alt: "Responsive image 5",
// 	},
// 	{
// 		url: "https://picsum.photos/id/6/1800/1800",
// 		alt: "Responsive image 6",
// 	},
// 	{
// 		url: "https://picsum.photos/id/7/1800/1800",
// 		alt: "Responsive image 7",
// 	},
// 	{
// 		url: "https://picsum.photos/id/8/1800/1800",
// 		alt: "Responsive image 8",
// 	},
// 	{
// 		url: "https://picsum.photos/id/9/1800/1800",
// 		alt: "Responsive image 9",
// 	},
// 	{
// 		url: "https://picsum.photos/id/10/1800/1800",
// 		alt: "Responsive image 10",
// 	},
// 	{
// 		url: "https://picsum.photos/id/11/1800/1800",
// 		alt: "Responsive image 11",
// 	},
// ];

export default images;
