import React from "react";
import { Link } from "react-router-dom";
// import imgChiller from "../assets/images/hero-chiller.png";
import pipesImg from "../assets/images/hero_pipes_fade.svg";

const Hero = () => {
	let brandImages = [
		"assets/images/brands/brand_1.png",
		"assets/images/brands/brand_2.png",
		"assets/images/brands/brand_3.png",
		"assets/images/brands/brand_4.png",
		"assets/images/brands/brand_5.png",
		"assets/images/brands/brand_6.png",
		"assets/images/brands/brand_7.png",
		"assets/images/brands/brand_8.png",
		"assets/images/brands/brand_9.png",
	];

	const ListingBrands = brandImages.map((img, index) => {
		return (
			<li
				className="logo"
				key={index}
			>
				<img
					src={img}
					alt={index}
				/>
			</li>
		);
	});

	return (
		<section className="hero">
			<div className="container hero__wrapper">
				<div className="hero__bg-image"></div>
				<div className="hero__center-image">
					{/* <img
						src={imgChiller}
						alt="chiller"
					/> */}
				</div>
				<div className="hero__bg-logo-pattern"></div>
				<div className="hero__content">
					<div
						className="bgImageContent"
						style={{ backgroundImage: `url("${pipesImg}")` }}
					/>
					<h1>
						Get it done.
						<br />
						Fast, Easy
					</h1>
					<p>
						We deliver HVAC/R and energy efficiency solutions tailored to your facilities, budget and goals.
						Trust us for your quality service and long-term value.
					</p>
					<Link
						to="/about"
						className="btn btn--secondary cta"
					>
						<span>Learn More</span>
						<i className="bx bx-chevron-right"></i>
					</Link>
				</div>

				<div className="container brand-carousel">
					<h6 className="title">Brands we have worked with:</h6>
					<div className="brandLogos">
						<div className="logosWrapper">
							<ul className="logos">{ListingBrands}</ul>
							<ul className="logos">{ListingBrands}</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
