import { React, useRef } from "react";
import { NavLink, Link } from "react-router-dom";

import Logo from "../assets/logo/logo_dark.png";
import navLinks from "../contents/navLinks";

const Navbar = (props) => {
	// console.log("NavBar Script is working");
	const { position, isBackground } = props;

	const toggleMenuRef = useRef();
	const navMenuRef = useRef();
	const toggleMenuIcon = () => {
		let toggleMenu = toggleMenuRef.current;
		let navMenu = navMenuRef.current;
		let visibility = navMenu.getAttribute("data-visible");

		if (visibility === "false") {
			navMenu.setAttribute("data-visible", true);
			toggleMenu.setAttribute("aria-expanded", true);
		} else {
			navMenu.setAttribute("data-visible", false);
			toggleMenu.setAttribute("aria-expanded", false);
		}
	};

	return (
		<nav
			className="navbar"
			style={{
				position: `${position ? "relative" : "absolute"}`,
				background: `${
					isBackground === "true"
						? "linear-gradient(180deg, hsl(var(--primary-50)) 55%, rgba(255, 255, 255, 0) 100%)"
						: "none"
				}`,
			}}
		>
			<div className="navbar__wrapper container">
				<div className="navbar__logo">
					<a href="\">
						<div className="sr-only">Hivac Logo</div>
						<img
							src={Logo}
							alt="LOGO"
						/>
					</a>
				</div>

				<button
					ref={toggleMenuRef}
					className="navbar__toggle"
					onClick={toggleMenuIcon}
					aria-controls="navbar__menu"
					aria-expanded="false"
				>
					<span className="sr-only">Menu</span>
				</button>

				<div
					ref={navMenuRef}
					id="navbar__menu"
					className="navbar__menu"
					data-visible="false"
				>
					<ul
						className="navbar__links"
						role="list"
					>
						{navLinks.map((navLink) => (
							<li
								className="navbar__link"
								key={navLink.id}
							>
								<NavLink
									data-role="navigation"
									to={navLink.url}
									aria-label={navLink.label}
								>
									<span>{navLink.label}</span>
								</NavLink>
							</li>
						))}
					</ul>

					<Link
						role="button"
						to="/contact"
						className="btn btn--secondary"
					>
						<span>Get in touch</span>
						<i className="bx bx-chevron-right"></i>
					</Link>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
