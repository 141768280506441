import navLinks from "../contents/navLinks";
import { NavLink } from "react-router-dom";

const socialLinks = [
	{
		id: 1,
		label: "instagram",
		url: "",
	},
	{
		id: 2,
		label: "linkedin",
		url: "",
	},
	{
		id: 3,
		label: "whatsapp",
		url: "",
	},
	{
		id: 4,
		label: "twitter",
		url: "",
	},
	{
		id: 5,
		label: "facebook",
		url: "",
	},
	{
		id: 6,
		label: "youtube",
		url: "",
	},
];

const Footer = ({ variant }) => {
	return (
		<footer className={`footer ${variant}`}>
			<div className="container">
				<div className="footer__wrapper">
					<div className="footer__top">
						<div className="footer__logo">
							<div className="sr-only">Hivac Logo</div>
							<div className="logo"></div>
						</div>

						<ul
							className="footer__navlinks"
							role="list"
						>
							{navLinks.map((navLink) => (
								<li
									className="footer__navlink"
									key={navLink.id}
								>
									<NavLink
										data-role="navigation"
										to={navLink.url}
										aria-label={navLink.label}
									>
										{navLink.label}
									</NavLink>
									{/* <a role='navigation' href='/' aria-current='page'>
										<span>Home</span>
									</a> */}
								</li>
							))}
						</ul>

						<ul
							className="footer__sociallinks"
							role="list"
						>
							{socialLinks.map((socialLink) => (
								<li
									className="footer__sociallink"
									key={socialLink.id}
								>
									<a
										href="/"
										aria-label={socialLink.label}
									>
										<i className={`bx bxl-${socialLink.label}`}></i>
									</a>
								</li>
							))}
						</ul>
					</div>
					<div className="footer__bottom">
						<div className="footer__copyright">
							<span>&copy;</span>
							<p>2023, Hivac Electro Mechanical W.L.L, All Right Reserved </p>
						</div>
						<div className="footer__signature">
							<p>
								Designed by{" "}
								<a
									target="_blank"
									href="https://naba.consulting/"
									rel="noreferrer"
								>
									Naba Consulting
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
