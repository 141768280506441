// eslint-disable-next-line react/display-name
const TextInputField = ({ attributes, register, inputError }) => {
	// eslint-disable-next-line no-unused-vars
	const { id, isFullWidth, required, name, label, type, pattern, placeholder, validate, ...input } = attributes;

	return (
		<fieldset
			key={id}
			className={isFullWidth ? "w-full" : ""}
			required={required}
			data-valid={inputError ? "false" : ""}
		>
			<label htmlFor={name}>{label}</label>
			<div className="form__input-wrapper">
				<input
					{...register}
					type={type}
					placeholder={placeholder}
					data-validate={validate.toString()}
				/>
			</div>
			<span className="error">{inputError?.message}</span>
		</fieldset>
	);
};

export default TextInputField;
