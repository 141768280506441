import Navbar from "../Components/__Navbar";
import ContactUs from "../Components/__ContactUsForm";
import PageHeader from "../Components/__PageHeader";
import { PageBanner } from "../Components/__PageBanner";
import { FeaturedServicesTwo } from "../Components/__FeaturedServices";
import { useEffect } from "react";
import bgImageMobile from "../assets/images/mobile_about_banner.webp";
import bgImageDefault from "../assets/images/about_banner.webp";
import oldManImgDefault from "../assets/images/old_man_3000.webp";
import oldManImgMobile from "../assets/images/mobile_old_man.webp";

const About = () => {
	useEffect(() => {
		document.title = "About Us | Hivacem";
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);
	return (
		<>
			<div className="header">
				<Navbar
					position="static"
					isBackground="false"
				/>
				<PageBanner
					title="About Us"
					bgImage={{ default: bgImageDefault, mobile: bgImageMobile }}
				/>
			</div>

			<div className="main">
				<section className="aboutUs container">
					<div className="aboutUs__wrapper">
						<div className="aboutUs__header">
							<PageHeader
								title="Who are we"
								para="We are Hivac Electro Mechanical, your trusted partner for all your HVAC/R needs. We are
								a professional company with over a decade of experience in providing high-quality and
								cost-effective solutions for commercial, industrial, and municipal facilities."
							/>
						</div>
						<div className="aboutUs__contentWrapper">
							<div className="img-container">
								<img
									className="img"
									srcSet={`
                                    ${oldManImgMobile} 600w,
                                    ${oldManImgDefault} 1200w,
                                    `}
									sizes="50vmin"
									alt="Old Man Engineer"
								/>
							</div>
							<div className="contents">
								<div className="content">
									<h3>What we do</h3>
									<p>
										We are committed to providing high-quality, reliable, and ethical HVAC/R
										services that meet our customers' needs and expectations. We understand the
										importance of having a comfortable and healthy indoor environment, whether it's
										at home or work. That's why we use the latest technology and equipment to
										install, maintain, and repair energy-efficient and environmentally friendly
										heating, cooling, and ventilation systems. We also offer professional advice on
										how to optimize your HVAC/R system's performance and lifespan. Honesty,
										integrity, and responsibility are our core values. We respect our customers,
										employees, and community. Your feedback is always welcome as we strive to
										improve our services. We're proud to be your trusted HVAC/R partner.
									</p>
								</div>
								<div className="content">
									<h3>Our Services</h3>
									<p>We offer a wide range of services to suit your HVAC/R systems, including:</p>
									<ul data-role="arrow-list">
										<li>Consultancy and technical support for HVAC/R projects</li>
										<li>Design and installation.</li>
										<li> Commissioning and testing.</li>
										<li>Operation and maintenance.</li>
										<li>Energy audits and optimization.</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* <!-- Our About Us/Featured Services --> */}
				<FeaturedServicesTwo theme="dark" />

				<ContactUs theme="default" />
			</div>
		</>
	);
};

export default About;
