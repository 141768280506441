export const PageBanner = (props) => {
	const { title, bgImage } = props;
	return (
		<section className="pageBanner container">
			<div className="pageBanner__image">
				<div className="overlay"></div>
				<img
					src={bgImage.default}
					srcSet={`
                    ${bgImage.mobile} 600w,
                    ${bgImage.default} 1200w,
                    `}
					sizes="100vmin"
					alt="banner background image"
				/>
			</div>
			<div className="pageBanner__wrapper container">
				<div className="pageBanner__header">
					<h1>{title}</h1>
				</div>
			</div>
		</section>
	);
};
