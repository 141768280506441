import logo from "../assets/logo/LOGO.svg";

const PageLoader = (props) => {
	const { isLoading } = props;

	return (
		<>
			{/* <h1>Page Loading</h1> */}
			<div
				className="loading-welcome welcome-screen"
				data-loading={isLoading.toString()}
			>
				<div className="progress" />
				<div className="logoWrapper">
					<img
						id="welcomeImg"
						loading="eager"
						src={logo}
						alt="pageLoader"
					/>
				</div>
			</div>
		</>
	);
};

export default PageLoader;
