/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import bgMap from "../assets/images/map_dark.png";
import TextInputField from "./__TextInputField";
import TextAreaInput from "./__TextAreaInputField";
import PhoneInputField from "./__PhoneInputField";
import { SuccessCardPop } from "./__SuccessCardPop";
import { ErrorCardPop } from "./__ErrorCardPop";
import contactInputs from "../contents/contactInputs";

const ZodValidationSchema = z.object({
	"first-name": z.string("First Name is required").min(1, "Please input a valid first name"),
	"last-name": z.string("Last Name is required").min(1, "Please input a valid last name"),
	"email-address": z.string("Email is required").email("Please input a valid Email"),
	phone: z
		.string("Phone number is required")
		.min(4, { message: "Must be a valid mobile number" })
		.max(14, { message: "Must be a valid mobile number" }),
	subject: z
		.string("Subject is required")
		.min(2, "Subject should be 2 or more words")
		.max(100, "Subject should not exceed more than 100 words"),
	description: z
		.string()
		.min(5, "Description should be at least 5 or more words")
		.max(5000, "Description should be 5000 words or less"),
});

const ContactUs = (props) => {
	// Setting the initials values for Contact Form Inputs
	const initialInputsState = { phoneDetails: {} };
	contactInputs.forEach((input) => {
		// if (input.name == "phone") {
		// 	initialInputsState[input.name] = `+97336366363`;
		// } else {
		// 	initialInputsState[input.name] = `test@gmail.com`;
		// }
		initialInputsState[input.name] = "";
	});
	// Inputs Components
	const {
		register,
		reset,
		handleSubmit,
		control,
		formState: { errors, isDirty, isValid },
	} = useForm({ defaultValues: initialInputsState, resolver: zodResolver(ZodValidationSchema), mode: "onBlur" });

	const [isBtnLoading, setIsBtnLoading] = useState("false");
	const [isSuccessCardVisible, setIsSuccessCardVisible] = useState(false);
	const [isErrorCardVisible, setIsErrorCardVisible] = useState(false);

	// Handle Form Submit input
	const handleFormSubmit = async (data, e) => {
		e.preventDefault();
		setIsBtnLoading("true");

		// Collect all the validated inputs from the user and send to backend.
		// const API_URL = "http://localhost:2201/index.php";
		const API_URL = "https://api.hivacem.com/index.php";
		// console.log(JSON.stringify(data));
		await fetch(API_URL, {
			method: "POST",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
			body: JSON.stringify(data),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(`Request Failed: ${response.status} ${response.statusText} `);
				}
				// console.log(response);
				return response.json();
			})
			.then((data) => {
				// console.log(data);
				setIsBtnLoading("false");
				setIsSuccessCardVisible(true); // render success alert to the user
				reset(); //  reset the form
			})
			.catch((error) => {
				// If error -> render error message to user
				setIsBtnLoading("false");
				setIsErrorCardVisible(true); // render error alert to the user
				console.log("ALERT!! Request Failed:\n", error);
			});
	};

	return (
		<section className={`container section section--${props.theme}`}>
			<div className="section__bg section__bg--top">
				<img
					src={bgMap}
					alt="Dotted Map"
				/>
			</div>
			<div className="section__wrapper">
				<div className="section__header">
					<h2>Get in Touch</h2>
					<div className="section__header-divider">
						<div className="divider-icon"></div>
					</div>
					<p>
						Call, email or use the form below to receive more information on our company. We look forward to
						hearing from you.
					</p>
				</div>

				<div className={`contact contact--${props.theme}`}>
					<div className="contact__wrapper">
						<div className="contact__form">
							{/* Pop Up Alert if Form is Successful */}
							{isSuccessCardVisible && (
								<SuccessCardPop
									isVisible={isSuccessCardVisible}
									setIsVisible={setIsSuccessCardVisible}
								/>
							)}

							{/* Pop Up Alert if Form Fails */}
							{isErrorCardVisible && (
								<ErrorCardPop
									isVisible={isErrorCardVisible}
									setIsVisible={setIsErrorCardVisible}
								/>
							)}

							<form
								className={`form form--${props.theme}`}
								onSubmit={handleSubmit(handleFormSubmit)}
								noValidate
							>
								{contactInputs.map((input) =>
									input.type === "phone" ? (
										<Controller
											key={input.name}
											name={input.name}
											control={control}
											render={({ field }) => (
												<PhoneInputField
													field={field}
													key={input.id}
													attributes={{ ...input }}
													inputError={errors[input.name]}
													register={{ ...register(input.name) }}
													error={!!errors.phone}
													helperText={errors.phone ? errors.phone?.message : ""}
												/>
											)}
										/>
									) : input.type === "select" ? (
										<h2>select input</h2>
									) : input.type === "textarea" ? (
										<TextAreaInput
											key={input.id}
											attributes={{ ...input }}
											register={{ ...register(input.name, { required: true }) }}
											inputError={errors[input.name]}
										/>
									) : (
										<TextInputField
											key={input.id}
											attributes={{ ...input }}
											register={{ ...register(input.name, { required: true }) }}
											inputError={errors[input.name]}
										/>
									)
								)}

								<fieldset className="w-full">
									<button
										type="submit"
										className="btn btn--full btn--primary"
										disabled={!isDirty || !isValid}
										loading={isBtnLoading}
									>
										<span>Send</span>
									</button>
								</fieldset>
							</form>
						</div>

						<div className="contact__details">
							<div className="wrapper">
								<div className="contact__detail">
									<h3>Office Address</h3>
									<p>
										Al Qudaybiyah, Manama
										<br />
										Block 308, Road 330 <br />
										Building 281, Office No 65 <br />
									</p>
								</div>
								<div className="contact__detail">
									<h3>Office Hours</h3>
									<p>
										Sunday - Thursday <br />
										8:00 am to 5:00 pm
									</p>
								</div>
								<div className="contact__detail">
									<h3>Call Us</h3>
									<div className="custom-para">
										<p>
											<span>TEL</span>
											<span>:</span>
											<span>+973 1750 2160</span>
										</p>
										<p>
											<span>MOB</span>
											<span>:</span>
											<span>+973 3382 9922</span>
										</p>
										<p>
											<span>MOB</span>
											<span>:</span>
											<span>+973 3384 9922</span>
										</p>
									</div>
								</div>
								<div className="contact__detail">
									<h3>Email Us</h3>
									<p>info@hivacem.com</p>
								</div>
							</div>
							<div className="contact__map">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d223.69389786904907!2d50.583173953703785!3d26.225863217519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbh!4v1722774413548!5m2!1sen!2sbh"
									width="600"
									height="450"
									style={{ border: 0 }}
									loading="lazy"
									referrerPolicy="no-referrer-when-downgrade"
									title="Office Location Map"
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactUs;
