const navLinks = [
	{
		id: 1,
		url: "/",
		label: "Home",
	},
	{
		id: 2,
		url: "/services",
		label: "Services",
	},
	{
		id: 3,
		url: "/gallery",
		label: "Gallery",
	},
	{
		id: 4,
		url: "/about",
		label: "About",
	},
	{
		id: 5,
		url: "/contact",
		label: "Contact",
	},
];

export default navLinks;
