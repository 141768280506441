export const SuccessCardPop = (props) => {
	const { isVisible, setIsVisible } = props;

	const closeCard = () => {
		setIsVisible(false);
	};

	return (
		<div
			className="cardpop"
			data-visible={isVisible}
		>
			<div className="cardpop__wrapper">
				<div
					className="cardpop__exit"
					onClick={closeCard}
				></div>
				<div className="cardpop__icon">
					<svg
						width="104"
						height="103"
						viewBox="0 0 104 103"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M98.433 56.9652C94.7132 75.3833 80.6896 92.7258 61.0119 96.601C51.4148 98.4936 41.4594 97.3397 32.5631 93.3033C23.6668 89.267 16.2832 82.5541 11.4637 74.1205C6.64413 65.6869 4.63433 55.9625 5.72044 46.3319C6.80655 36.7014 10.9332 27.6556 17.5128 20.4827C31.0082 5.76296 53.7956 1.71098 72.3945 9.07822"
							stroke="currentColor"
							strokeWidth="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M35.1968 49.5976L53.7957 68.0157L98.4331 20.1287"
							stroke="currentColor"
							strokeWidth="10"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="cardpop__title">Thank you</div>
				<div className="cardpop__para">
					Your form has been submitted successfully. Our team will contact you shortly.
				</div>
			</div>
		</div>
	);
};
