import { useEffect } from "react";

import { PageBanner } from "../Components/__PageBanner";
import Navbar from "../Components/__Navbar";
import PageHeader from "../Components/__PageHeader";
import ImagesGrid from "../Components/__ImagesGrid";
import bgImageMobile from "../assets/images/mobile_gallery_banner.webp";
import bgImageDefault from "../assets/images/gallery_banner.webp";

const Gallery = () => {
	useEffect(() => {
		document.title = "Gallery | Hivacem";
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			<div className="header">
				<Navbar
					position="static"
					isBackground="false"
				/>
				<PageBanner
					title="Gallery"
					bgImage={{ default: bgImageDefault, mobile: bgImageMobile }}
				/>
			</div>

			<div className="main">
				<section
					id="gallery"
					className="gallery container"
				>
					<div
						className="gallery__wrapper"
						data-visible
					>
						<div className="gallery__header">
							<PageHeader
								title="Our Work in Action"
								para="The photos and videos featured here showcase our expertise and experience in all aspects of HVAC/R, from installation and repair to maintenance and optimization."
							/>
						</div>
						<div className="gallery__content">
							<ImagesGrid />
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
export default Gallery;
