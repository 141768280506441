import { Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import "./scss/style.scss";

import Footer from "./Components/__Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Services from "./pages/Services";
import PageLoader from "./Components/__PageLoader";

function App() {
	const [isLoading, setIsLoading] = useState(true);

	//  TODO: Default value is 2000
	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	}, []);

	return (
		<>
			<PageLoader isLoading={isLoading} />
			<div className="App">
				<Routes>
					<Route
						path="/"
						element={<Home />}
					/>
					<Route
						path="/services"
						element={<Services />}
					/>
					<Route
						path="/about"
						element={<About />}
					/>
					<Route
						path="/contact"
						element={<Contact />}
					/>
					<Route
						path="/gallery"
						element={<Gallery />}
					/>
				</Routes>

				<Footer variant="footer--dark" />
			</div>
		</>
	);

	// return isLoading ? (
	// 	<PageLoader isLoading={isLoading} />
	// ) : (
	// 	<div className="App">
	// 		<Routes>
	// 			<Route
	// 				path="/"
	// 				element={<Home />}
	// 			/>
	// 			<Route
	// 				path="/services"
	// 				element={<Services />}
	// 			/>
	// 			<Route
	// 				path="/about"
	// 				element={<About />}
	// 			/>
	// 			<Route
	// 				path="/contact"
	// 				element={<Contact />}
	// 			/>
	// 		</Routes>

	// 		<Footer variant="footer--dark" />
	// 	</div>
	// );
}

export default App;
