/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PhoneInput, {
	parsePhoneNumber,
	formatPhoneNumber,
	formatPhoneNumberIntl,
	isValidPhoneNumber,
	isPossiblePhoneNumber,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";

// eslint-disable-next-line react/display-name
const PhoneInputField = ({ field, attributes, register, inputError, control }) => {
	const { id, isFullWidth, required, name, label, type, pattern, placeholder, validate, ...input } = attributes;
	const { onChange, onBlur, value } = field;

	const [isInputValid, setIsInputValid] = useState();
	// const [value, setValue] = useState(React);

	const handleChange = (data) => {
		console.log("Phone input", data);
	};

	return (
		<fieldset
			className={isFullWidth ? "w-full" : ""}
			required={required}
			data-valid={inputError ? "false" : ""}
		>
			<label htmlFor={name}>{label}</label>
			<div className="form__input-wrapper">
				<PhoneInput
					international
					initialValueFormat="national"
					placeholder={placeholder}
					defaultCountry="BH"
					onChange={onChange}
					onBlur={onBlur}
					value={value}
					countryCallingCodeEditable={false}
					id={id}
				/>
			</div>

			<span className="error">{inputError?.message}</span>
		</fieldset>
	);
};

export default PhoneInputField;

// const PhoneInputField = (props, ref) => {
// 	const [isInputValid, setIsInputValid] = useState();
// 	const [errorMessage, setErrorMessage] = useState("");
// 	const [value, setValue] = useState("");

// 	const { name } = props;
// 	const commonAttributes = {
// 		id: name,
// 		autoComplete: props.autoComplete,
// 		placeholder: props.placeholder,
// 		required: props.required,
// 		validate: props.validate.toString(),
// 	};
// 	const handleChange = async (e) => {
// 		if (!value) return;

// 		const { name } = commonAttributes;
// 		props.value = value;
// 		await props.onChange(name, props.value);
// 	};

// 	const handleBlur = async (e) => {
// 		if (!value) return;
// 		const { name } = commonAttributes;
// 		// Check for Validation
// 		if (!isPossiblePhoneNumber(value)) {
// 			await props.handleInputBlur(name, false);
// 			setIsInputValid(false);
// 			setErrorMessage(props.errorMessage);
// 			return;
// 		}
// 		// Prase all the information of the User inputted number
// 		const parsedNumber = parsePhoneNumber(value);
// 		const phoneDetails = {
// 			country: parsedNumber.country,
// 			countryCallingCode: parsedNumber.countryCallingCode,
// 			nationalNumber: parsedNumber.nationalNumber,
// 			number: parsedNumber.number,
// 		};
// 		// Setting the value of the parent component
// 		props.value.phone = formatPhoneNumberIntl(value);
// 		props.value.phoneDetails = phoneDetails;
// 		// Sent to Parent Component "__ContactUs" to confirm respective input is valid
// 		await props.handleInputBlur(name, true);
// 		setIsInputValid(true);
// 		setErrorMessage("");
// 	};

// 	return (
// 		<fieldset
// 			className={props.isFullWidth ? "w-full" : ""}
// 			required={props.required}
// 			data-valid={isInputValid}
// 		>
// 			<label htmlFor={props.name}>{props.label}</label>
// 			<div className="form__input-wrapper">
// 				<PhoneInput
// 					ref={ref}
// 					international
// 					initialValueFormat="national"
// 					defaultCountry="BH"
// 					countryCallingCodeEditable={false}
// 					{...commonAttributes}
// 				/>
// 			</div>

// 			<span className="error">{!isInputValid ? errorMessage : ""}</span>
// 		</fieldset>
// 	);
// };

// // const PhoneInputField = (props) => {
// // 	const [isInputValid, setIsInputValid] = useState();
// // 	const [errorMessage, setErrorMessage] = useState('');
// // 	const [value, setValue] = useState('');

// // 	const name = props.name;
// // 	const commonAttributes = {
// // 		name: name,
// // 		id: name,
// // 		autoComplete: props.autoComplete,
// // 		placeholder: props.placeholder,
// // 		required: props.required,
// // 		validate: props.validate.toString(),
// // 	};

// // 	const handleChange = async (e) => {
// // 		if (!value) return;

// // 		const name = commonAttributes.name;
// // 		props.value = value;
// // 		await props.onChange(name, props.value);
// // 	};

// // 	const handleBlur = async (e) => {
// // 		if (!value) return;
// // 		const name = commonAttributes.name;
// // 		// Check for Validation
// // 		if (!isPossiblePhoneNumber(value)) {
// // 			await props.handleInputBlur(name, false);
// // 			setIsInputValid(false);
// // 			setErrorMessage(props.errorMessage);
// // 			return;
// // 		}
// // 		// Prase all the information of the User inputted number
// // 		const parsedNumber = parsePhoneNumber(value);
// // 		const phoneDetails = {
// // 			country: parsedNumber.country,
// // 			countryCallingCode: parsedNumber.countryCallingCode,
// // 			nationalNumber: parsedNumber.nationalNumber,
// // 			number: parsedNumber.number,
// // 		};
// // 		// Setting the value of the parent component
// // 		props.value.phone = formatPhoneNumberIntl(value);
// // 		props.value.phoneDetails = phoneDetails;
// // 		// Sent to Parent Component "__ContactUs" to confirm respective input is valid
// // 		await props.handleInputBlur(name, true);
// // 		setIsInputValid(true);
// // 		setErrorMessage('');
// // 	};

// // 	return (
// // 		<fieldset className={props.isFullWidth ? 'w-full' : ''} required={props.required} data-valid={isInputValid}>
// // 			<label htmlFor={props.name}>{props.label}</label>
// // 			<div className='form__input-wrapper'>
// // 				<PhoneInput international initialValueFormat='national' defaultCountry='BH' countryCallingCodeEditable={false} onBlur={handleBlur} onChange={setValue} value={value} {...commonAttributes} />
// // 			</div>

// // 			<span className='error'>{!isInputValid ? errorMessage : ''}</span>
// // 		</fieldset>
// // 	);
// // });

// export default PhoneInputField;
