import { useState } from "react";
import images from "../contents/galleryImages";
import Modal from "./__Modal";

console.log(location.pathname);

const totalImages = Object.keys(images).length;
const ImagesGrid = () => {
	const [selectedImg, setSelectedImg] = useState({});
	const [isLoading, setIsLoading] = useState("false");
	const [imagesVisible, setImagesVisible] = useState(4);
	const [remainingImages, setRemainingImages] = useState(totalImages - imagesVisible);
	const [buttonVisible, setButtonVisible] = useState(true);

	const showMoreImages = () => {
		setIsLoading("true");
		setTimeout(() => {
			if (imagesVisible >= totalImages) {
				setButtonVisible(false);
				return;
			}

			setImagesVisible((prevValue) => prevValue + imagesVisible);
			setRemainingImages(totalImages - imagesVisible);
			setIsLoading("false");
		}, 1000);
		console.log("Total: ", totalImages, "      Visible: ", imagesVisible, "      Remaining: ", remainingImages);
	};

	return (
		<>
			<div className="imagesGrid">
				{images &&
					images.slice(0, imagesVisible).map((image, index) => (
						<div
							className="imagesGrid__wrapper"
							key={index}
							onClick={() => setSelectedImg({ index: index, ...image })}
						>
							<img
								src={image.src}
								alt={image.alt}
							/>
							<div className="label">
								<span>{image.alt}</span>
							</div>
						</div>
					))}

				{/* If the Object is empty don't render Modal Component */}
				{Object.keys(selectedImg).length !== 0 && (
					<Modal
						selectedImg={selectedImg}
						setSelectedImg={setSelectedImg}
					/>
				)}
				{buttonVisible && (
					<button
						className="btn btn--secondary imagesGrid__btn"
						onClick={showMoreImages}
						loading={isLoading}
						type="button"
					>
						<span>Show More</span>
					</button>
				)}
			</div>
		</>
	);
};

export default ImagesGrid;
