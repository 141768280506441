const contactInputs = [
	{
		id: 1,
		name: "first-name",
		type: "text",
		label: "First name",
		autoComplete: "given-name",
		placeholder: "Enter your first name",
		errorMessage: "Please enter a valid first name that is more than 2 letters.",
		pattern: "/[a-zA-Z\\s]{2,}$/",
		validate: true,
		required: true,
	},
	{
		id: 2,
		name: "last-name",
		type: "text",
		label: "Last name",
		autoComplete: "given-name",
		placeholder: "Enter your last name",
		errorMessage: "Please enter a valid last name that is more than 2 letters.",
		pattern: "/[a-zA-Z\\s]{2,}$/",
		validate: true,
		required: true,
	},
	{
		id: 3,
		name: "email-address",
		type: "email",
		label: "Email",
		autoComplete: "given-email",
		placeholder: "Enter your email address",
		errorMessage: "Please enter a valid email.",
		pattern: "/^[a-zA-Z0-9._]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}$/",
		validate: true,
		required: true,
	},
	{
		id: 4,
		name: "phone",
		type: "phone",
		label: "Phone",
		autoComplete: "given-number",
		placeholder: "Enter your phone number",
		errorMessage: "Please enter a valid phone number.",
		pattern: "/",
		validate: true,
		required: true,
	},
	// {
	// 	id: 5,
	// 	name: 'organization',
	// 	type: 'text',
	// 	label: 'Organization',
	// 	autoComplete: 'organization-name',
	// 	placeholder: 'Enter your organization name.',
	// 	errorMessage: 'Please input your organization name',
	// 	pattern: '/^[a-zA-Z\\s]{2,}$',
	// 	validate: true,
	// 	required: true,

	// },
	// {
	// 	id: 6,
	// 	name: 'designation',
	// 	type: 'text',
	// 	label: 'Designation',
	// 	autoComplete: 'designation-name',
	// 	placeholder: 'Enter your designation (CEO, CFO, Executive etc.)',
	// 	errorMessage: 'Please input your designation name',
	// 	pattern: '/^[a-zA-Z\\s]{2,}$',
	// 	validate: true,
	// 	required: true,

	// },
	// {
	// 	id: 7,
	// 	name: 'services',
	// 	type: 'select',
	// 	label: 'Services',
	// 	errorMessage: 'Please select any one services you like to enquire.',
	// 	pattern: '/^.{1,}$',
	// 	options: [
	// 		{
	// 			label: 'Select Service',
	// 			value: '',
	// 			defaultValue: true,
	// 		},
	// 		{
	// 			label: 'Sales Enquiry',
	// 			value: 'sales-enquiry',
	// 		},
	// 		{
	// 			label: 'Client Support',
	// 			value: 'client-support',
	// 		},
	// 		{
	// 			label: 'Other Services',
	// 			value: 'others',
	// 		},
	// 	],
	// 	validate: true,
	// 	required: true,
	// 	isFullWidth: true,

	// },
	{
		id: 8,
		name: "subject",
		type: "text",
		label: "Subject",
		autoComplete: "suject-name",
		placeholder: "What would you like to enquiry about?",
		errorMessage: "Please enter your subject name",
		pattern: "/^[a-zA-Z\\s]{2,}$/",
		validate: true,
		required: true,
		isFullWidth: true,
	},
	{
		id: 9,
		name: "description",
		type: "textarea",
		label: "Description",
		placeholder: "How can we help you?",
		errorMessage: "Please enter description that is more than 5 letters.",
		pattern: "/^[a-zA-Z\\s]{5,}$/",
		validate: true,
		required: true,
		isFullWidth: true,
	},
];

export default contactInputs;
