import { Link } from "react-router-dom";

import bg_worker from "../assets/images/worker_bg.png";
import icon_vrf from "../assets/images/services/vrf_icon.svg";
import icon_consultancy from "../assets/images/services/consultancy-icon.svg";
import icon_engineer from "../assets/images/services/eng_icon_2.svg";

import services from "../contents/services";

// Only Featured Services will be stored
const featuredServices = services.filter((value) => {
	return value.isFeatured === true;
});

const iconMap = {
	Consultancy: icon_consultancy,
	Engineering: icon_engineer,
	"Variable Refrigerant Flow": icon_vrf,
};

const FeaturedCards = () => {
	return (
		<div className="cards">
			<div className="cards__wrapper">
				{featuredServices.map((service, index) => (
					<div
						className="card card--light"
						key={`index-${index}`}
					>
						<div className="card__info">
							<div className="card__img">
								<img
									src={iconMap[service.title]}
									alt="team-icon"
								/>
							</div>
							<h3 className="card__title">{service.title}</h3>
							<p className="card__para">{service.teaser}</p>
						</div>
						<div className="card__btn">
							{/* <a role='button' href='' className='btn btn--secondary btn--link'>
                        <span>Learn More</span>
                        <i className='bx bx-chevron-right'></i>
                    </a> */}
						</div>
					</div>
				))}
			</div>
			<div className="cards__btn">
				<Link
					role="button"
					to="/services"
					className="btn btn--secondary"
				>
					<span>Show More</span>
					<i className="bx bx-chevron-right"></i>
				</Link>
			</div>
		</div>
	);
};

export const FeaturedServicesOne = () => {
	return (
		<section className="container section">
			<div className="section__bg section__bg--top section__bg--sm">
				<img
					src={bg_worker}
					alt="Construction worker"
				/>
			</div>
			<div className="section__wrapper">
				<div className="section__header">
					<h2>Featured Services</h2>
					<div className="section__header-divider">
						<div className="divider-icon"></div>
					</div>
					<p></p>
				</div>
				<FeaturedCards />
			</div>
		</section>
	);
};

export const FeaturedServicesTwo = (props) => {
	let { theme } = props;
	theme === "dark" ? (theme = "section--dark") : "";

	return (
		<section className={`container section ${theme} section--heading-2`}>
			<div className="section__bg section__bg--top section__bg--sm">
				<img
					src={bg_worker}
					alt="Construction worker"
				/>
			</div>
			<div className="section__wrapper">
				<div className="section__header">
					<h2>HOW CAN WE HELP</h2>
					<div className="section__header-divider">
						<div className="divider-icon"></div>
					</div>
					<p>Featured Services</p>
				</div>
				<FeaturedCards />
			</div>
		</section>
	);
};
