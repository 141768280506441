import galleryImages from "../contents/galleryImages";

const Modal = ({ selectedImg, setSelectedImg }) => {
	const { src, alt, index } = selectedImg;

	const handleClose = (e) => {
		if (e.target.classList.contains("modal__backdrop") || e.target.classList.contains("modal__close")) {
			setSelectedImg({});
		}
	};

	const handlePrevButton = () => {
		index === 0
			? setSelectedImg({ index: galleryImages.length - 1, ...galleryImages[galleryImages.length - 1] })
			: setSelectedImg({ index: index - 1, ...galleryImages[index - 1] });
	};

	const handleNextButton = () => {
		index + 1 === galleryImages.length
			? setSelectedImg({ index: 0, ...galleryImages[0] })
			: setSelectedImg({ index: index + 1, ...galleryImages[index + 1] });
	};

	return (
		<div className="modal">
			<div
				className="modal__backdrop"
				onClick={handleClose}
			>
				<i
					className="bx bx-x-circle modal__close modal__icon"
					onClick={handleClose}
				/>
				<i
					className="bx bx-chevron-left modal__navigation-left modal__icon"
					onClick={handlePrevButton}
				/>

				<div className="modal__imgWrapper">
					<img
						src={src}
						alt={`enlarged ${alt}`}
					/>
					<p className="label">{alt}</p>
				</div>
				<i
					className="bx bx-chevron-right modal__navigation-right modal__icon"
					onClick={handleNextButton}
				/>
			</div>
		</div>
	);
};

export default Modal;
