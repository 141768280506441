import { Link } from "react-router-dom";
import bgCity from "../assets/images/city_bg.png";
import iconTeam from "../assets/images/team-icon.svg";
import iconVision from "../assets/images/vision-icon.svg";
import iconGoal from "../assets/images/goal-icon.svg";

const AboutUs = () => {
	const aboutCardsContent = [
		{
			icon: iconTeam,
			alt: "team-icon",
			label: "Our Team",
			description:
				"We are qualified engineers and technicians are committed to excellence in every project. We’re certified by leading industry associations and manufacturers and invest in continuous learning to keep up with the latest HVAC/R trends.",
		},
		{
			icon: iconVision,
			alt: "vision-icon",
			label: "Our Vision",
			description:
				"We provide high-quality, dependable, and ethical products and services. We satisfy and meet the needs of our customers. We are concerned about society and the environment. We are truthful in our words and actions.",
		},
		{
			icon: iconGoal,
			alt: "goal-icon",
			label: "Our Goals",
			description:
				"Our goal is to facility owners and managers with heating, cooling and energy saving solutions. We work according to high standards and focus on long-term customer relationships that add value.",
		},
	];

	return (
		<section className="container section section--light">
			<div className="section__bg section__bg--bottom section__bg--light">
				<img
					src={bgCity}
					alt=""
				/>
			</div>
			<div className="section__wrapper">
				<div className="section__header">
					<h2>Who are we</h2>
					<div className="section__header-divider">
						<div className="divider-icon" />
					</div>
					<p></p>
				</div>
				<div className="cards">
					<div className="cards__wrapper">
						{aboutCardsContent.map((card, index) => (
							<div
								className="card card--light"
								key={`${index}_${card.alt}`}
							>
								<div className="card__info">
									<div className="card__img">
										<img
											src={card.icon}
											alt={card.alt}
										/>
									</div>
									<h3 className="card__title">{card.label}</h3>
									<p className="card__para">{card.description}</p>
								</div>
								<div className="card__btn" />
							</div>
						))}
					</div>
					<div className="cards__btn">
						<Link
							role="button"
							to="/about"
							className="btn btn--secondary"
						>
							<span>Learn More</span>
							<i className="bx bx-chevron-right" />
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
