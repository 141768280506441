export const ErrorCardPop = (props) => {
	const { isVisible, setIsVisible } = props;

	const closeCard = () => {
		setIsVisible(false);
	};

	return (
		<div
			className="cardpop cardpop--error"
			data-visible={isVisible}
		>
			<div className="cardpop__wrapper">
				<div
					className="cardpop__exit"
					onClick={closeCard}
				></div>
				<div className="cardpop__icon">
					<svg
						width="108"
						height="108"
						viewBox="0 0 108 108"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle
							cx="54"
							cy="54"
							r="49"
							stroke="#E11D48"
							strokeWidth="10"
						/>
						<path
							d="M36.4551 30.2272L54 47.7714L71.454 30.3181C71.8396 29.9077 72.304 29.5795 72.8195 29.353C73.335 29.1265 73.8909 29.0064 74.4539 29C75.6594 29 76.8156 29.4789 77.668 30.3312C78.5204 31.1836 78.9993 32.3397 78.9993 33.5451C79.0099 34.1024 78.9065 34.6559 78.6954 35.1717C78.4844 35.6876 78.1702 36.1549 77.772 36.5449L60.0907 53.9982L77.772 71.6788C78.5212 72.4116 78.9604 73.404 78.9993 74.4513C78.9993 75.6567 78.5204 76.8128 77.668 77.6652C76.8156 78.5175 75.6594 78.9964 74.4539 78.9964C73.8747 79.0204 73.2967 78.9238 72.7568 78.7125C72.2169 78.5012 71.7268 78.18 71.3177 77.7692L54 60.225L36.5005 77.7238C36.1165 78.1204 35.6577 78.4371 35.1506 78.6555C34.6435 78.874 34.0982 78.9898 33.5461 78.9964C32.3406 78.9964 31.1844 78.5175 30.332 77.6652C29.4796 76.8128 29.0007 75.6567 29.0007 74.4513C28.9901 73.894 29.0935 73.3405 29.3046 72.8247C29.5156 72.3088 29.8298 71.8415 30.228 71.4515L47.9093 53.9982L30.228 36.3177C29.4788 35.5848 29.0396 34.5924 29.0007 33.5451C29.0007 32.3397 29.4796 31.1836 30.332 30.3312C31.1844 29.4789 32.3406 29 33.5461 29C34.6369 29.0136 35.6824 29.4545 36.4551 30.2272Z"
							fill="#E11D48"
						/>
					</svg>
				</div>
				<div className="cardpop__title">Error!</div>
				<div className="cardpop__para">
					We apologize for the error in our contact form. We are working to fix the issue as soon as possible.
					In the meantime, please try again later or contact us directly at info@hivacem.com
				</div>
			</div>
		</div>
	);
};
