import React, { useState, useRef, useEffect } from "react";
import Navbar from "../Components/__Navbar";
import { PageBanner } from "../Components/__PageBanner";
import PageHeader from "../Components/__PageHeader";
import services from "../contents/services";
import ContactUs from "../Components/__ContactUsForm";
import serviceImgDefault from "../assets/images/service_banner.webp";
import serviceImgMobile from "../assets/images/mobile_service_banner.webp";

const Services = () => {
	const [options, setOptions] = useState(services); //    List all the services on Page start
	const [menuTitle, setMenuTitle] = useState("Select Service");

	const [servicesInfo, setServicesInfo] = useState([services[0]]);

	const selectRef = useRef();
	const optionsRef = useRef();

	useEffect(() => {
		document.title = "Services | Hivacem";
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
		optionsRef;
	}, []);

	// // Updates the data-selected attribute to true/false attribute if User click on the options
	// const isSelected = (selectedItem) => {
	// 	services.map((service) => {
	// 		return service.title === selectedItem ? 'true' : 'false';
	// 	});
	// };

	// Adds style, changes Menu Title to selected option and Closes options Menu after selection.
	const updateName = (selectedItem) => {
		const optionsTag = optionsRef.current.children;
		for (let i = 0; i < optionsTag.length; i++) {
			optionsTag[i].setAttribute("data-selected", "false");
		}
		selectedItem.target.setAttribute("data-selected", "true");
		setMenuHeadingText(selectedItem.target.innerText);
		toggleOptionMenu();
		updateServiceContent(selectedItem);
	};

	// Updates the Menu Title.
	const setMenuHeadingText = (text) => {
		setMenuTitle(text);
	};

	// Toggles Option Menu Button in Mobile View
	const toggleOptionMenu = () => {
		const ele = selectRef.current;
		if (ele.getAttribute("data-visible")) {
			return ele.removeAttribute("data-visible");
		}
		return ele.setAttribute("data-visible", "true");
	};

	// Filter List based on Search
	const handleOptionInputChange = (e) => {
		setOptions("");
		let arr = []; //create a empty array
		let inputValue = e.target.value.toLowerCase();
		arr = services.filter((data) => {
			return data.title.toLowerCase().startsWith(inputValue);
		});
		arr.length > 0 ? setOptions(arr) : setOptions([{ title: "Oops! Service not found." }]);
	};

	// Set Services Info based on user selection from OptionsMenu
	function updateServiceContent(selectedLi) {
		setServicesInfo([]);
		const selectedTitle = selectedLi.target.innerText.toLowerCase();
		let selectedArr = [];
		selectedArr = services.filter((data) => {
			return data.title.toLowerCase() === selectedTitle.toLowerCase();
		});
		setServicesInfo(selectedArr);
	}

	// Set Default Option at the start of the page
	// const defaultOption = () => {
	// let selectedItem = optionsRef.current.children[0];
	// let selectedItem = optionsRef;
	// console.log(selectedItem);
	// selectedItem.target.setAttribute('data-selected', 'true');
	// console.log(selectedItem);
	// setMenuHeadingText(selectedItem.target.innerText);
	// updateServiceContent(selectedItem);
	// };

	return (
		<>
			<div className="header">
				<Navbar
					position="static"
					isBackground="false"
				/>
				<PageBanner
					title="Services"
					bgImage={{ default: serviceImgDefault, mobile: serviceImgMobile }}
				/>
			</div>

			<div className="main">
				<section
					id="services"
					className="services container"
				>
					<div
						className="services__wrapper"
						data-visible
					>
						<div className="services__header">
							<PageHeader
								title="Our Expertise"
								para="Our team are experienced in these following services."
							/>
						</div>
						<div className="services__content">
							<div
								className="services__select"
								ref={selectRef}
							>
								<button
									className="select-btn"
									onClick={toggleOptionMenu}
								>
									<div className="title">{menuTitle}</div>
									<i className="bx bxs-chevron-down"></i>
								</button>

								<div className="content">
									<div className="search">
										<i className="bx bx-search"></i>
										<input
											type="text"
											spellCheck="false"
											placeholder="Search"
											onChange={handleOptionInputChange}
										/>
									</div>
									<ul
										className="options"
										ref={optionsRef}
									>
										{/* Dynamic Options Render */}
										{options.map((service) => {
											return (
												<li
													key={service.id}
													onClick={updateName}
													data-selected="false"
												>
													{service.title}
												</li>
											);
										})}
									</ul>
								</div>
							</div>
							<div className="services__info">
								{/* <!-- Javascript fills the content responsively --> */}
								{servicesInfo.map((service) => (
									<div key={`1.${service.id}`}>
										<div className="header">
											<h4>{service.title}</h4>
											<h5>{service["sub-heading"]}</h5>
											<div className="info-divider">
												<div className="divider-icon"></div>
											</div>
										</div>

										{Object.entries(service.description).map((info, index) => (
											<React.Fragment key={`body-${index}`}>
												<div
													className="body"
													key={`2.${index}`}
												>
													{info[1].heading.length === 0 ? "" : <h6>{info[1].heading}</h6>}
													<p>{info[1].paragraph}</p>
													<ul data-role="arrow-list">
														{info[1].list.map((liInfo, index) => (
															<li key={`3.${index}`}>{liInfo}</li>
														))}
													</ul>
												</div>
												{info[1].images.length === 0 ? (
													""
												) : (
													<div className="services__carousel">
														{/* <!-- Tiny Slider --> */}
														<div id="s-carousel_wrapper">
															<div
																className="s-carousel"
																id="s-carousel"
															>
																{/* <!-- Images are responsively loaded from JS --> */}
																{info[1].images.map((img, index) => (
																	<div
																		className="slide-img"
																		key={`img-${index}`}
																	>
																		<div
																			className="img"
																			style={{
																				backgroundImage: `url(${img})`,
																			}}
																			alt="carousel-image"
																		></div>
																	</div>
																))}
															</div>
														</div>
													</div>
												)}
											</React.Fragment>
										))}
									</div>
								))}
							</div>
							<div className="services__actions">
								<div className="wrapper">
									<a
										className="btn btn--primary btn--outline"
										href="#"
									>
										<i className="bx bxs-file-pdf"></i>
										<span>Services Guide.pdf</span>
									</a>
									<a
										target="_blank"
										className="btn btn--primary btn--outline"
										href="/assets/documents/company_profile.pdf"
									>
										<i className="bx bxs-file-pdf"></i>
										<span>Company Profile.pdf</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>

				<ContactUs theme="light" />
			</div>
		</>
	);
};

export default Services;
