const TextAreaInput = ({ attributes, register, inputError }) => {
	// eslint-disable-next-line no-unused-vars
	const { id, isFullWidth, required, name, label, type, pattern, placeholder, validate, autoComplete, ...input } =
		attributes;
	return (
		<fieldset
			key={id}
			className={isFullWidth ? "w-full" : ""}
			required={required}
			data-valid={inputError ? "false" : ""}
		>
			<label htmlFor={name}>{label}</label>
			<div className="form__input-wrapper">
				<textarea
					{...register}
					id={name}
					autoComplete={autoComplete}
					placeholder={placeholder}
					data-validate={validate.toString()}
				/>
			</div>
			<span className="error">{inputError?.message}</span>
		</fieldset>
	);
};

export default TextAreaInput;
