import { useEffect } from "react";
import ContactUsForm from "../Components/__ContactUsForm";
import Navbar from "../Components/__Navbar";
import { PageBanner } from "../Components/__PageBanner";
import contactImgDefault from "../assets/images/contact_banner.webp";
import contactImgMobile from "../assets/images/mobile_contact_banner.webp";

function Contact() {
	useEffect(() => {
		document.title = "Contact Us | Hivacem";
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			<div className="header">
				<Navbar
					position="static"
					isBackground="false"
				/>
				<PageBanner
					title="Contact Us"
					bgImage={{ default: contactImgDefault, mobile: contactImgMobile }}
				/>
			</div>

			<div className="main">
				<ContactUsForm
					theme="light"
					style={{ paddingTop: "2rem" }}
				/>
			</div>
		</>
	);
}

export default Contact;
