const services = [
	{
		id: 1,
		title: "Consultancy",
		isFeatured: true,
		icon: "",
		"sub-heading": "Get the most out of your air conditioning system with our expert advice.",
		teaser: "Our consultants have years of experience in the air conditioning industry and can help you with all your air conditioning needs, from initial consultation to final commissioning.",
		description: [
			{
				heading: "",
				paragraph:
					"Our consultants have years of experience in the air conditioning industry and can help you with all your air conditioning needs, from initial consultation to final commissioning. Our team will study your air conditioning system performance and functional deficiencies, and provide you with solutions to improve your system's efficiency and reliability. Moreover, we will help you choose the right air conditioning system for your needs and budget.",
				list: [
					"Statement inspection, analysis, and reporting rectification method.",
					"Investigating operational issues and offering solutions and Researching the system and its components, as well as creating operation and maintenance manuals",
					"Consultation on Chiller Plant and System Operation and Maintenance.",
					"Audit of energy consumption, Air conditioning system performance and functional deficiencies are studied, and solutions are reported/provided.",
				],
				images: [],
			},
			{
				heading: "",
				paragraph:
					"We will provide you with a detailed plan for the installation and commissioning of your new air conditioning system. Through our consultation you can Increased energy efficiency, Reduced operating costs, Improved comfort, extended lifespan of your air conditioning system.",
				list: [],
				images: [],
			},
		],
	},
	{
		id: 2,
		title: "Engineering",
		isFeatured: true,
		icon: "",
		"sub-heading": "Design, engineer, and install HVAC/R systems",
		teaser: "We offer a comprehensive range of engineering services for HVAC/R systems, from design and engineering to installation and maintenance. We can help you choose the right system for your needs, and ensure that it is installed correctly and operates efficiently.",
		description: [
			{
				heading: "",
				paragraph:
					"We have a team of experienced and qualified engineers who can handle all of your HVAC/R engineering needs. We are committed to providing our customers with the highest quality service, and we are confident that we can help you meet your HVAC/R goals. We will take into account your building's size, climate, and occupancy to create a system that is both efficient and effective.",
				list: [],
				images: [],
			},
			{
				heading: "",
				paragraph:
					"We use the latest technology to ensure that your system is as efficient as possible and provide you with a detailed design and specification for your new air conditioning system. Moreover, we will work with you to obtain the necessary permits and approvals.",
				list: [],
				images: [],
			},
			{
				heading: "",
				paragraph: "A custom-designed air conditioning system will be more: ",
				list: [
					"Efficient and effective than a generic system.",
					"Tailored to your specific needs and budget.",
					"More reliable and durable.",
					"Easier to maintain.",
				],
				images: [],
			},
		],
	},
	{
		id: 3,
		title: "Project Execution",
		isFeatured: false,
		icon: "",
		"sub-heading":
			"We manage your air conditioning project from start to finish, so you can relax and enjoy the cool air.",
		teaser: "We will take care of everything from procurement to installation to commissioning, so you can be confident that your project will be completed on time and within budget.",
		description: [
			{
				heading: "",
				paragraph:
					"We will manage the entire project from start to finish, from procurement to installation to commissioning. We will ensure that your project is completed on time and within budget, and that you are satisfied with the final product.",
				list: [
					"We will commission your new air conditioning system to ensure that it is operating properly and efficiently.",
					"We will provide you with training on how to operate and maintain your new air conditioning system.",
					"You can be confident that your project will be completed on time and within budget",
				],
				images: [],
			},
		],
	},
	{
		id: 4,
		title: "Technical Services",
		isFeatured: false,
		icon: "",
		"sub-heading": "We provide a wide range of technical services for your air conditioning system.",
		teaser: "Our technicians are experts in air conditioning repair, maintenance, and upgrade. We can help you keep your air conditioning system running smoothly and efficiently.",
		description: [
			{
				heading: "",
				paragraph:
					"We offer a variety of technical services to help you maintain your air conditioning system in peak condition. Our team will assist you if your conditioning system is not working properly, improve efficiency of HVAC/R, or upgrade your units to latest technology. We offer a wide range of technical services for your air conditioning system, including:",
				list: [
					"Repair:    We can repair all types of air conditioning problems, from minor leaks to major breakdowns.",
					"Maintenance:    We can help you develop a maintenance plan for your air conditioning system to keep it running smoothly and efficiently.",
					"Upgrade:    We can help you upgrade your air conditioning system to the latest technology, saving you money on energy costs.",
				],
				images: [],
			},
		],
	},
	{
		id: 5,
		title: "Asset Management",
		isFeatured: false,
		icon: "",
		"sub-heading": "We help you track, maintain, and optimize your air conditioning assets.",
		teaser: "Our asset management software will give you real-time visibility into your air conditioning assets, so you can make informed decisions about repairs, maintenance, and upgrades.",
		description: [
			{
				heading: "",
				paragraph:
					"We can help you manage your air conditioning assets by developing a comprehensive asset management plan. This plan will help you track the performance of your assets, identify potential problems, and plan for future maintenance and upgrades.",
				list: [
					"Our asset management software tracks the performance of your air conditioning assets, so you can identify potential problems early on.",
					"Our software also helps you schedule preventive maintenance, so you can avoid costly repairs.",
					"We can also help you optimize your air conditioning assets, so you can save money on energy costs.",
				],
				images: [],
			},
		],
	},
	{
		id: 6,
		title: "Project",
		isFeatured: false,
		icon: "",
		"sub-heading":
			"We can also help you optimize your air conditioning assets, so you can save money on energy costs.",
		teaser: "We will take care of everything from procurement to installation to commissioning, so you can be confident that your project will be completed on time and within budget.",
		description: [
			{
				heading: "",
				paragraph:
					"We are currently working on a number of exciting projects, including the installation of a new air conditioning system at a local hospital, the upgrade of an air conditioning system at a university campus, and the construction of a new data center.",
				list: [
					"Our team will work with you to develop a detailed project plan and, source and procure all of the necessary materials and equipment.",
					"We will install your new air conditioning system according to the highest standards and ensure that it is operating properly and efficiently.",
					"We will also provide you with training on how to operate and maintain your new HVAC/R systems.",
				],
				images: [],
			},
		],
	},
	{
		id: 7,
		title: "Testing & Commissioning",
		isFeatured: false,
		icon: "",
		"sub-heading": "Ensure your HVAC/R system is operating efficiently and effectively",
		teaser: "Testing & commissioning is the process of ensuring that your HVAC/R system is operating as designed and to its full potential. This includes testing the individual components of the system, as well as the system as a whole.",
		description: [
			{
				heading: "",
				paragraph:
					"Testing & commissioning can help to identify and correct any problems with your HVAC/R system, before they cause major issues. This can save you money on energy costs, improve the comfort of your indoor environment, and extend the lifespan of your system. Moreover, We will test and commission your new air conditioning system to ensure that it is operating properly and efficiently. This includes testing the system's components, calibrating the controls, and training your staff on how to operate the system.",
				list: [
					"Identify and correct problems with your HVAC/R system",
					"Save money on energy costs",
					"Improve the comfort of your indoor environment",
					"Extend the lifespan of your system",
				],
				images: [],
			},
		],
	},
	{
		id: 8,
		title: "Mechanical System",
		isFeatured: false,
		icon: "",
		"sub-heading": "Design, install, and maintain your HVAC/R system",
		teaser: "We offer a comprehensive range of mechanical system services, from design and installation to maintenance and repair. We can help you choose the right system for your needs, and ensure that it is installed correctly and operates efficiently.",
		description: [
			{
				heading: "",
				paragraph:
					"We offer a variety of services for mechanical systems, including air conditioning, heating, ventilation, and plumbing.Our team of experienced and qualified engineers and technicians who can handle all of your mechanical system needs. We are committed to providing our customers with the highest quality service, and we are confident that we can help you meet your HVAC/R goals. Our mechanical system services include:",
				list: [
					"Designing and installing new HVAC/R systems",
					"Upgrading existing HVAC/R systems",
					"Maintaining and repairing HVAC/R systems",
					"Troubleshooting HVAC/R problems",
				],
				images: [],
			},
		],
	},
	{
		id: 9,
		title: "Building Automation Controls",
		isFeatured: false,
		icon: "",
		"sub-heading": "Modernize your HVAC/R system with building automation controls",
		teaser: "Building automation controls (BAC) can help you to improve the efficiency, comfort, and security of your HVAC/R system. BAC can also help you to save money on energy costs.",
		description: [
			{
				heading: "",
				paragraph:
					"We can help you integrate your air conditioning system with your building automation system to create a more efficient and comfortable environment. Our services include designing, installing, and commissioning building automation systems. BAC is a system that uses sensors and controllers to monitor and control the operation of your HVAC/R system. This can be done remotely, from a computer or smartphone.",
				list: [
					"Improve the efficiency of your HVAC/R system",
					"Increase the comfort of your indoor environment",
					"Save money on energy costs",
					"Improve the security of your building",
				],
				images: [],
			},
			{
				heading: "",
				paragraph:
					"If you are looking to improve the efficiency, comfort, and security of your HVAC/R system, then BAC is a great option. BAC can help you to:",
				list: [
					"Reduce energy consumption",
					"Improve indoor air quality",
					"Maximize uptime",
					"Minimize downtime",
					"Extend the lifespan of your HVAC/R system",
				],
				images: [],
			},
		],
	},
	{
		id: 10,
		title: "Chillers Control System",
		isFeatured: false,
		icon: "",
		"sub-heading": "Optimize the performance of your chiller system with a chiller control system",
		teaser: "A chiller control system is a device that monitors and controls the operation of your chiller system. This can help you to improve the efficiency, performance, and reliability of your chiller system.",
		description: [
			{
				heading: "",
				paragraph:
					"We can help you design, install, and commission a chiller control system for your air conditioning system. Our services include providing you with a custom-designed system that meets your specific needs, as well as training your staff on how to operate the system. A chiller control system typically includes a controller, sensors, and actuators. The controller receives input from the sensors and uses this information to determine the optimal operating conditions for the chiller system. The actuators then adjust the operation of the chiller system to meet the controller's instructions.",
				list: [
					"Improve the efficiency of your chiller system",
					"Increase the lifespan of your chiller system",
					"Reduce the risk of chiller failures",
					"Save money on energy costs",
				],
				images: [],
			},
			{
				heading: "",
				paragraph:
					"If you are looking to improve the efficiency, performance, and reliability of your chiller system, then a chiller control system is a great option. A chiller control system can help you to:",
				list: ["Reduce energy consumption", "Minimize downtime", "Extend the lifespan of your chiller system"],
				images: [],
			},
		],
	},
	{
		id: 11,
		title: "Compressor Overhaul",
		isFeatured: false,
		icon: "",
		"sub-heading": "Extend the lifespan of your compressor with a compressor overhaul",
		teaser: "A compressor overhaul is a process of inspecting, repairing, and replacing worn or damaged components in a compressor. This can help to extend the lifespan of the compressor and improve its efficiency.",
		description: [
			{
				heading: "",
				paragraph:
					"If your compressor is not working properly, or if it is showing signs of wear and tear, then it may be time for a compressor overhaul. A compressor overhaul can help to extend the lifespan of your compressor and improve its efficiency. We can overhaul your compressor to extend its lifespan and improve its efficiency. Our services include dismantling the compressor, inspecting and repairing the components, and reassembling the compressor.",
				list: [
					"Improve the efficiency of your HVAC/R system",
					"Extend the lifespan of your compressor",
					"Reduce the risk of compressor failures",
					"Save money on energy costs",
				],
				images: [],
			},
			{
				heading: "",
				paragraph: "A compressor overhaul typically includes the following steps:",
				list: [
					"Disassembling the compressor",
					"Inspecting the components for wear and damage",
					"Repairing or replacing damaged components",
					"Reassembling the compressor",
					"Testing the compressor to ensure it is operating properly",
				],
				images: [],
			},
		],
	},
	{
		id: 12,
		title: "Variable Refrigerant Flow",
		isFeatured: true,
		icon: "",
		"sub-heading": "Provide precise climate control with variable refrigerant flow",
		teaser: "Variable refrigerant flow (VRF) is a type of HVAC system that uses a compressor to vary the amount of refrigerant flowing through the system. This allows the system to provide precise climate control in different areas of a building.",
		description: [
			{
				heading: "",
				paragraph:
					"We can help you upgrade your air conditioning system to variable refrigerant flow (VRF) technology. VRF systems are more efficient than traditional air conditioning systems and can save you money on your energy bills. VRF systems are becoming increasingly popular for commercial and residential applications. They offer a number of advantages over traditional HVAC systems, including:",
				list: ["Precise climate control", "Energy efficiency", "Flexibility", "Quiet operation"],
				images: [],
			},
			{
				heading: "",
				paragraph:
					"If you are looking for a HVAC system that can provide precise climate control and energy efficiency, then VRF is a great option. VRF systems can help you to:",
				list: [
					"Save money on energy costs",
					"Improve the comfort of your indoor environment",
					"Extend the lifespan of your HVAC system",
				],
				images: [],
			},
		],
	},

	{
		id: 13,
		title: "Chiller Compressors",
		isFeatured: false,
		icon: "",
		"sub-heading": "Types of Compressors Used in Chillers",
		teaser: "Chiller compressors are the essential components that move the refrigerant through the refrigeration cycle, absorbing heat from the evaporator and expelling it to the condenser.",
		description: [
			{
				heading: "",
				paragraph:
					"Chiller compressors are the essential components that move the refrigerant through the refrigeration cycle, absorbing heat from the evaporator and expelling it to the condenser. Most common types of compressors are:",
				list: [],
				images: [],
			},
			{
				heading: "Centrifugal Compressors",
				paragraph:
					"Centrifugal compressors are the most common type of compressor used in chillers. They are typically used in large chillers, such as those used in commercial and industrial applications. This type has an impeller that rotates and flings the refrigerant particles out at high velocity and pressure. It is often mounted above the chiller with a large volute shaped pipe.",
				list: [
					"Advantages: High efficiency, low noise, and compact design.",
					"Disadvantages: High initial cost, and not suitable for small chillers.",
				],
				images: ["/assets/images/services/cc-2.png", "/assets/images/services/cc-1.gif"],
			},
			{
				heading: "Screw Compressors",
				paragraph:
					"Screw compressors are also a common type of compressor used in chillers. This is a variation of the centrifugal type that has two stage compressors and magnetic bearings. It is smaller and more efficient than the conventional centrifugal type. This type has two interconnecting screws that push the refrigerant into a smaller space as they rotate. It can be used for both water cooled and air cooled chillers. They are typically used in medium to large chillers and are a good choice for applications where high efficiency is required. Screw compressors work by rotating two screws, which intermesh and trap the refrigerant.",
				list: [
					"Advantages: High efficiency, reliable operation, and long lifespan.",
					"Disadvantages: High initial cost, and not suitable for small chillers.",
				],
				images: [
					"/assets/images/services/sc-1.png",
					"/assets/images/services/sc-3.png",
					"/assets/images/services/sc-2.gif",
				],
			},
			{
				heading: "Scroll Compressors",
				paragraph:
					"Scroll compressors are a newer type of compressor that is becoming increasingly popular in chillers. They are typically used in small to medium chillers and are a good choice for applications where quiet operation is required. This type has two discs, one stationary and one rotating, that compress the refrigerant into a tighter spiral. It is mostly used for air cooled chillers and often joined in a bank.",
				list: [
					"Advantages: Quiet operation, compact design, and low maintenance.",
					"Disadvantages: Lower efficiency than centrifugal or screw compressors.",
				],
				images: ["/assets/images/services/scc-1.png", "/assets/images/services/scc-2.gif"],
			},
			{
				heading: "Reciprocating Compressors",
				paragraph:
					"Reciprocating compressors are the oldest type of compressor used in chillers. They are typically used in small chillers and are a good choice for applications where low initial cost is important. This type has a number of pistons and chambers that move up and down to compress the refrigerant. It is very strong and reliable but also expensive to operate. It is becoming less common in commercial refrigeration.",
				list: [
					"Advantages: Low initial cost, and simple design.",
					"Disadvantages: Lower efficiency than other types of compressors, and noisy operation.",
				],
				images: ["/assets/images/services/rc-1.png", "/assets/images/services/rc-2.gif"],
			},
			{
				heading: "Turbocor Compressors",
				paragraph:
					"Turbocor compressors are a type of centrifugal compressor that uses a rotating scroll to compress the refrigerant. They are typically used in small to medium chillers and are a good choice for applications where high efficiency and quiet operation are required. This is a variation of the centrifugal type that has two stage compressors and magnetic bearings. It is smaller and more efficient than the conventional centrifugal type.",
				list: [
					"Advantages: High efficiency, quiet operation, and compact design.",
					"Disadvantages: High initial cost.",
				],
				images: ["/assets/images/services/tc-1.png", "/assets/images/services/tc-2.gif"],
			},
		],
	},
];

export default services;
