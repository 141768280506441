import React, { useEffect } from "react";

import AboutUs from "../Components/__AboutUs";
import { FeaturedServicesOne } from "../Components/__FeaturedServices";
import ContactUs from "../Components/__ContactUsForm";
import Hero from "../Components/__Hero";
import Navbar from "../Components/__Navbar";

const Home = () => {
	useEffect(() => {
		document.title = "Hivac Electromachinal Service";
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);
	return (
		<>
			<div className="header">
				<Navbar isBackground="true" />
				<Hero />
			</div>
			<div className="main">
				<AboutUs />
				<FeaturedServicesOne />
				<ContactUs theme="dark" />
			</div>
		</>
	);
};

export default Home;
