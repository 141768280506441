const PageHeader = ({ title, para }) => {
	return (
		<div className="pageHeader">
			<h2 className="pageHeader__title">{title}</h2>
			<p className="pageHeader__para">{para}</p>
			<div className="seperator seperator--dark seperator--lg">
				<div className="seperator__icon"></div>
			</div>
		</div>
	);
};

export default PageHeader;
